<template>
  <!-- 用户账户 -->
  <div id="app">
    <div class="center-tit">我的账户<span>/My Account</span></div>
    <div class="account-con">
      <div class="account-list">
        <div class="list-le">
          <img src="@/assets/center/email.png" />
          <h2 v-if="userStatusData.email">
            邮箱{{ userInfoData.email }}已绑定
          </h2>
          <h2 v-else>邮箱未绑定</h2>
          <p>可用邮箱密码登录网站，可用邮箱找回密码</p>
        </div>
        <div class="list-rg" v-if="userStatusData.email">
          <p class="renzheng">
            <img src="@/assets/center/renzheng-icon.png" />已绑定
          </p>
        </div>
        <div class="list-rg" v-else>
          <p class="btn" @click="showBindEmail">添加绑定</p>
        </div>
      </div>
      <VerifyEmailBind
        :visible="bindEmailVisible"
        @change-visible="(val) => (bindEmailVisible = val)"
        @reload="getUserData"
      ></VerifyEmailBind>

      <ChangePhone
        :visible="changePhoneVisible"
        @change-visible="(val) => (changePhoneVisible = val)"
        @reload="getUserData"
      ></ChangePhone>
      <!-- <BindPhone
        v-if="!userStatusData.phone"
        :visible="bindPhoneVisible"
        @change-visible="(val) => (bindPhoneVisible = val)"
        @reload="getUserData"
      ></BindPhone> -->
      <div class="account-list">
        <div class="list-le">
          <img src="@/assets/center/password.png" style="width: 25px" />
          <h2>密码{{ userStatusData.password ? "已" : "未" }}设置</h2>
          <p>用于保护账号信息和登录安全</p>
        </div>
        <div class="list-rg">
          <p
            :class="['btn', userStatusData.password ? 'renzheng' : '']"
            @click="setPassword"
          >
            <img
              v-if="userStatusData.password"
              src="@/assets/center/renzheng-icon.png"
            />{{ userStatusData.password ? "更换密码" : "添加绑定" }}
          </p>
        </div>
      </div>
      <div class="account-list">
        <div class="list-le">
          <img src="@/assets/center/mobile.png" style="width: 20px" />
          <h2 v-if="userStatusData.phone">
            手机号+{{ userInfoData.phone }}已绑定
          </h2>
          <h2 v-else>手机号未绑定</h2>
          <p>可用手机加密码/验证码登录网站</p>
        </div>
        <div class="list-rg" v-if="userStatusData.phone">
          <p
            class="renzheng"
            @click="changePhoneVisible = true"
            style="cursor: pointer"
          >
            <img src="@/assets/center/renzheng-icon.png" />更换手机
          </p>
        </div>
        <div class="list-rg" v-else>
          <p
            class="btn"
            @click="changePhoneVisible = true"
            style="cursor: pointer"
          >
            添加绑定
          </p>
        </div>
      </div>

      <ResetPassword
        v-if="!userStatusData.password"
        :visible="setPasswordVisible"
        @change-visible="(val) => (setPasswordVisible = val)"
        @reload="getUserData"
      ></ResetPassword>

      <ChangePassword
        v-if="userStatusData.password"
        :visible="setPasswordVisible"
        @change-visible="(val) => (setPasswordVisible = val)"
        @reload="getUserData"
      ></ChangePassword>
      <div class="account-list">
        <div class="list-le">
          <img src="@/assets/center/wechat.png" style="width: 30px" />
          <h2>微信账号绑定</h2>
          <p>绑定微信账号后，可用微信登录</p>
        </div>
        <div class="list-rg">
          <p v-if="!userStatusData.wechat" class="btn" @click="opening">
            添加绑定
          </p>
          <p v-else class="renzheng">
            <img src="@/assets/center/renzheng-icon.png" />已绑定
          </p>
        </div>
      </div>
    </div>

    <div
      class="mask"
      v-show="bindingWechat"
      @click="
        bindingWechat = false;
        msg = '';
      "
    ></div>
    <el-dialog
      :visible.sync="bindingWechat"
      class="verVisible"
      append-to-body
      width="400px"
      :close-on-click-modal="false"
    >
      <div class="ver-panel">
        <div class="ver-head">
          <div class="l-title">绑定微信号</div>
        </div>
        <div class="ver-code">
          <img :src="qrcode" style="width: 140px; height: 140px" />
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from "vuex";
import {
  wechatBind,
  sendVerificationCode,
  getUserStatusData,
  getUserInfoData,
} from "@/service/index.js";
import VerifyEmailBind from "@/views/1_user/account/VerifyEmailBind";
import ResetPassword from "@/views/1_user/account/ResetPassword";
import ChangePhone from "@/views/1_user/account/VerifyPhone.vue";
import ChangePassword from "@/views/1_user/account/ChangePassword";
export default {
  name: "account",
  components: { VerifyEmailBind, ResetPassword, ChangePhone, ChangePassword },
  data() {
    return {
      idx: 0,
      stateIndex: 0,
      bindingWechat: false,
      qrcode: "",
      cerVisible: false,
      cerVisible2: false,
      cerPhoneform: {
        phone: "",
        code: "",
        password: "",
      },
      cerEmailform: {
        email: "",
        code: "",
        password: "",
      },
      show: true,
      count: "",
      timer: null,
      show2: true,
      count2: "",
      timer2: null,
      countrys: [
        {
          value: "美国 & 加拿大",
          label: "+1",
          val: "1",
        },
        {
          value: "中国",
          label: "+86",
          val: "86",
        },
        {
          value: "台湾",
          label: "+886",
          val: "886",
        },
        {
          value: "香港",
          label: "+852",
          val: "852",
        },
        {
          value: "澳大利亚",
          label: "+61",
          val: "61",
        },
        {
          value: "英国",
          label: "+44",
          val: "44",
        },
        {
          value: "德国",
          label: "+49",
          val: "49",
        },
        {
          value: "爱尔兰",
          label: "+353",
          val: "353",
        },
        {
          value: "瑞士",
          label: "+41",
          val: "41",
        },
        {
          value: "荷兰",
          label: "+31",
          val: "31",
        },
        {
          value: "法国",
          label: "+33",
          val: "33",
        },
        {
          value: "西班牙",
          label: "+34",
          val: "34",
        },
        {
          value: "新西兰",
          label: "+64",
          val: "64",
        },
        {
          value: "俄罗斯",
          label: "+7",
          val: "7",
        },
        {
          value: "韩国",
          label: "+82",
          val: "82",
        },
        {
          value: "日本",
          label: "+81",
          val: "81",
        },
        {
          value: "阿拉伯联合酋长国",
          label: "+971",
          val: "971",
        },
      ],
      countrysvalue: "1",
      passwordExist: false,
      bindEmailVisible: false,
      userStatusData: {},
      userInfoData: {},
      setPasswordVisible: false,
      changePhoneVisible: false,
    };
  },
  computed: mapState(["userInfo"]),
  watch: {},
  created() {},
  mounted() {
    this.getUserData();
  },
  methods: {
    getUserData() {
      getUserStatusData().then((res) => {
        this.userStatusData = res.result;
      });
      getUserInfoData().then((res) => {
        this.userInfoData = res.result;
      });
    },
    showBindEmail() {
      this.bindEmailVisible = true;
    },
    tabNav(index) {
      this.tabList.forEach((element, i) => {
        if (i == index) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      });

      this.idx = index;
    },
    setPassword() {
      if (!this.userStatusData.phone) {
        return this.$message.warning("请先绑定手机号，然后设置密码");
      } else {
        this.setPasswordVisible = true;
      }
    },
    opening() {
      this.bindingWechat = true;
      this.$nextTick(() => {
        this.getBindingWechatQrCode();
      });
    },
    getBindingWechatQrCode() {
      wechatBind().then((res) => {
        console.log(res.result);
        if (res.result) {
          this.qrcode = res.result.img;
        }
      });
    },
  },
};
</script>

<style scoped>
.ver-code {
  width: 142px;
  height: 142px;
  margin-left: auto;
  margin-right: auto;
  font-size: 0;
  border: 1px solid #eceef2;
  margin-bottom: 40px;
  background: #fff;
}
.verVisible ::v-deep .el-dialog {
  border-radius: 10px;
}
.verVisible ::v-deep .el-dialog__body {
  padding: 10px 30px;
}
.ver-head {
  height: 60px;
  text-align: center;
  color: #333;
  .l-title {
    font-size: 16px;
  }
  .l-sub-title {
    font-size: 13px;
  }
  .l-desc {
    color: #999;
    margin-top: 10px;
    font-size: 12px;
  }
}
.mask {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* 6.17 */
.cert-dialog {
  position: fixed;
  width: 590px;
  box-sizing: border-box;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.cer-flex {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.flex-bd {
  flex: 1;
}
.cer-title {
  padding: 15px 0;
  text-align: center;
  font-size: 16px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #34495e;
}
.cer-form {
  padding: 15px 0;
}
.cer-form .btn-code {
  width: 100px;
  height: 32px;
  padding: 0;
  text-align: center;
  border: 0;
  margin-left: 15px;
  color: #34495e;
  background: rgba(136, 148, 171, 0.2);
  border-radius: 2px;
}
.cer-form .btn-diable {
  opacity: 0.5;
  cursor: auto;
}
.tip-span {
  font-size: 14px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
  line-height: 14px;
  margin-left: 15px;
}

.select-row {
  display: block;
}
.select-row .label {
  display: inline-block;
  width: 60px;
}

.ali-box .next {
  width: 88px;
}
.ali-box .cancel {
  width: 88px;
  height: 32px;
  background: linear-gradient(to left, #34495e, #9da6b0);
  background: -moz-linear-gradient(to left, #34495e, #9da6b0);
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 3px;
  margin-left: 30px;
}
::v-deep .el-dialog__body {
  padding: 50px 30px;
  padding-top: 20px;
}
::v-deep .el-dialog {
  border-radius: 5px;
}
</style>

<style>
/* 6.17 */
.cer-form .cer-select {
  width: 80px;
}
.cer-form .cer-select .el-input__inner {
  border: 1px solid #dcdfe6 !important;
  background: rgba(235, 236, 241, 0.43);
  border-radius: 4px 0 0 4px;
}
.cer-form .cer-select + .flex-bd .el-input__inner {
  border-radius: 0 4px 4px 0;
  border-left: 0;
}
.submit-con .el-tabs__header {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.submit-con .el-tabs__item {
  width: 50%;
  padding: 0;
}
.submit-con .el-tabs__nav {
  width: 100%;
}
.submit-con .el-tabs__item:hover {
  color: #fa6400;
}
.submit-con .el-tabs__item.is-active {
  color: #fa6400;
}
.submit-con .el-tabs__active-bar {
  background-color: #fa6400;
}
.cer-flex .el-input__inner {
  height: 36px;
}
.el-select-dropdown__item.selected {
  color: #fa6400;
  font-size: 500 !important;
}
</style>
